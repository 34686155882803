<template>
  <form @submit="reqSend">
    <div class="sn-form__row">
      <input
        maxlength="50"
        v-model="form.name"
        class="sn-form__input"
        type="text"
        :placeholder="trans('label.your_name')"
      >
    </div>
    <div class="sn-form__row">
      <input
        v-model="form.phone"
        v-mask="'+7 (###) ###-##-##'"
        class="sn-form__input"
        type="text"
        placeholder="+7 (___) ___-__-__"
      >
    </div>
    <div class="sn-form__row">
      <input
        maxlength="50"
        v-model="form.email"
        class="sn-form__input"
        type="text"
        :placeholder="trans('label.email')"
      >
    </div>
    <div class="sn-form__row">
      <textarea
        maxlength="3000"
        v-model="form.message"
        class="sn-form__textarea"
        :placeholder="trans('label.message')"
      />
    </div>
    <div class="row justify-content-end">
      <div class="col-12 col-sm-auto">
        <button
          class="sn-btn sn-btn--thm-green sn-btn--size-b sn-btn--outline"
          :disabled="loading || !validate"
        >
          {{ trans('action.send') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'VFormContacts',

  data() {
    return {
      loading: false,
      form: {
        name: '',
        phone: '',
        email: '',
        message: '',
      },
    };
  },

  computed: {
    validate() {
      return this.form.name !== ''
              && this.form.phone !== ''
              && this.form.message !== '';
    },
  },

  methods: {
    async reqSend(e) {
      e.preventDefault();
      this.loading = true;
      const res = await this.$http({
        method: 'POST',
        url: `https://college.snation.kz/${this.$store.state.common.lang}/tko/feedback-snation`,
        data: {
          name: this.form.name,
          phone: this.form.phone,
          email: this.form.email,
          message: this.form.message,
        },
      });

      if (res.errorType === 422
        && res.data && res.data.errors && Object.keys(res.data.errors).length) {
        this.$notify({
          type: 'error',
          title: this.trans('label.error'),
          text: res.data.errors[Object.keys(res.data.errors)[0]][0],
          duration: 7000,
        });
      }

      if (!res.error) {
        this.form = {
          name: '',
          phone: '',
          email: '',
          message: '',
        };
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
