<template>
  <section class="sn-container-content-wrapper sn-view__contacts">
    <div class="sn-container-content">
      <div class="sn-title sn-title--double sn-upper">
        <span>{{ trans('label.contacts') }}</span>
      </div>
      <div class="sn-contacts">
        <div class="sn-contacts__form">
          <v-form-contacts/>
        </div>
        <div class="sn-contacts__info">
          <div class="sn-contacts__info-wrapper">
            <div class="sn-contacts__info-label">{{ trans('label.address') }}</div>
            <div class="sn-contacts__info-text">{{ trans('text.contacts.address') }}</div>
          </div>
          <div class="sn-contacts__info-wrapper">
            <div class="sn-contacts__info-label">{{ trans('label.telephone') }}</div>
            <div
              class="sn-contacts__info-text"
              v-html="trans('text.contacts.phone_list')"
            />
          </div>
          <div class="sn-contacts__info-wrapper">
            <div class="sn-contacts__info-label">{{ trans('label.we_in_social') }}</div>
            <ul class="sn-contacts__info-social">
              <li
                v-for="(item, itemIdx) in social"
                :key="itemIdx"
                :class="`sn-contacts__info-social--${item.id}`"
              >
                <a
                  :href="item.href"
                  target="_blank"
                >{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VFormContacts from '@/components/VFormContacts';

export default {
  name: 'VContacts',

  components: {
    VFormContacts,
  },

  computed: {
    social() {
      return this.$store.state.translator[this.$store.getters.interfaceLang].text.contacts.social;
    },
  },
};
</script>
